import { Component, OnInit } from '@angular/core';
import {Event,Router,NavigationStart,NavigationEnd,NavigationCancel,NavigationError} from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
showLoadingIndicator = true;
  constructor(private _router:Router) {
    this._router.events.subscribe((routerEvent:Event)=>{

      if(routerEvent instanceof NavigationStart){
        this.showLoadingIndicator=true;
      }
      if(routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel ){
        this.showLoadingIndicator=false;
      }
      
    });
   }

  ngOnInit() {
  }
}
