import { Component, OnInit } from '@angular/core';
import { Dish } from '../shared/dish';
import { DishService } from '../services/dish.service';
import { Promotion } from '../shared/promotion';
import { PromotionService } from '../services/promotion.service';
//import * as M from '../assets/materialize/js/materialize.min.js';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { AfterContentInit, ViewChild } from '@angular/core';

import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { MatGridList } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  
  providers: [NgbCarouselConfig]

})

export class HomeComponent implements OnInit {
  @ViewChild('grid') grid: MatGridList;
 
  //resposive grid

  gridByBreakpoint = 
  {
    xl: 8,
    lg: 6,
    md: 4,
    sm: 2,
    xs: 1
  }
  dish:Dish;
  promotion:Promotion;
  dishes:Dish[];
  breakpoint:any;
  public imagesUrl;
  images = [
  
    "/assets/images/11.jpg",
    "/assets/images/18.jpg",
    "/assets/images/15.jpg",
    "/assets/images/12.jpg",
  ];

  constructor(private dishservice: DishService,
    private promotionservice: PromotionService,config: NgbCarouselConfig,
    private observableMedia: ObservableMedia)
    {
    // customize default values of carousels used by this component tree
    config.interval = 8500;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.wrap = true;
  }
 

  ngOnInit() {
    this.dishes=this.dishservice.getDishes();
    
    this.promotion = this.promotionservice.getFeaturedPromotion();      
    this.imagesUrl = [  
    '/assets/images/dessert-1.jpg',
    '/assets/images/dessert-2.jpg',
    '/assets/images/dessert-3.jpg',
    '/assets/images/dessert-4.jpg',
    '/assets/images/lunch-1.jpg',
    '/assets/images/lunch-2.jpg',
    '/assets/images/lunch-3.jpg',
    '/assets/images/lunch-4.jpg',
    '/assets/images/breakfast-2.jpg',
    '/assets/images/breakfast-3.jpg',
    '/assets/images/breakfast-4.jpg',
    '/assets/images/drink-1.jpg',
    '/assets/images/drink-2.jpg',
    '/assets/images/drink-3.jpg',
    '/assets/images/drink-5.jpg',
    '/assets/images/drink-6.jpg',
    '/assets/images/soup (1).jpg',
    '/assets/images/soup (2).jpg',
    ];
    //var instances=M.carousel.init(elems,this.options);
  }

  
}

 