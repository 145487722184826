import { Dish } from './dish';

export const DISHES: Dish[] = [
    {
        id: '0',
        name: 'SOUTH COMBO',
        image: '/assets/images/indian.jpg',
        category: 'Breakfast',
        featured: false,
        label: 'Lunch',
        price: 'Rs.350/-',
        description: 'SOUTH COMBO',
        
    },
    {
        id: '1',
        name: 'CHERMOULA MARINATED FISH',
        image: '/assets/images/breakfast-4.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.510/-',
        description: 'A delectable,New York Style marinated fish, spiced with Indian cardamoms',
        
    },
    {
        id: '2',
        name: 'CRISPY CHICKEN ',
        image: '/assets/images/breakfast-5.jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.350/-',
        description: 'fried chicken season with herbs & fried, serve with chili mayo',
        
    },
    {
        id: '3',
        name: 'BERRY CHEESE CAKE',
        image: '/assets/images/dessert 2.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.290/-',
        description: 'BERRY CHEESE CAKE',
        
    },
   
];


export const DESSERT: Dish[] = [
    {
        id: '0',
        name: 'BANANA FOSTER',
        image: '/assets/images/breakfast-9.jpg',
        category: 'dessert',
        featured: true,
        label: 'dessert',
        price: 'Rs.210/- ',
        // tslint:disable-next-line:max-line-length
        description: 'BANANA FOSTER',
      
    },
    {
        id: '1',
        name: 'PANNA COTTA',
        image: '/assets/images/breakfast-8.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.280/-',
        description: 'PANNA COTTA(STRAWBERRY)',
        
    },
    {
        id: '2',
        name: 'TIRAMISU',
        image: '/assets/images/dessert-2.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.230/-',
        description: 'TIRAMISU',
        
    },
    {
        id: '3',
        name: 'CHOCOLATE BROWNIE WITH ICE CREAM',
        image: '/assets/images/dessert-5.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.270/-',
        description: 'CHOCOLATE BROWNIE WITH ICE CREAM ',
        
    },
    {
        id: '4',
        name: 'AMERICAN CHOCOLATE TART ',
        image: '/assets/images/dessert-3.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.300/-',
        description: 'AMERICAN CHOCOLATE TART',
        
    },
    {
        id: '5',
        name: 'PAN CAKE',
        image: '/assets/images/dessert-1.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.180/-',
        description: 'PAN CAKE',
        
    },
    {
        id: '6',
        name: 'SPL SUNDAE',
        image: '/assets/images/dessert-4.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.230/-',
        description: 'SPL SUNDAE',     
    },
    {
        id: '7',
        name: 'BERRY CHEESE CAKE',
        image: '/assets/images/dessert 2.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.290/-',
        description: 'BERRY CHEESE CAKE',
        
    },
];


export const DRINKS: Dish[] = [
    {
        id: '0',
        name: 'LIME RICKEY',
        image: '/assets/images/drink-2.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.140/- ',
        // tslint:disable-next-line:max-line-length
        description: 'Shallow crumb fried chicken breast serve with mash potato',
      
    },
    {
        id: '1',
        name: 'WATERMELON JUICE',
        image: '/assets/images/drink-1.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.160/- ',
        // tslint:disable-next-line:max-line-length
        description: 'WATERMELON JUICE',
      
    },
    {
        id: '2',
        name: 'MISTY MINT',
        image: '/assets/images/drink-5.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.120/- ',
        // tslint:disable-next-line:max-line-length
        description: 'MISTY MINT',
      
    },
    {
        id: '3',
        name: 'ORANGE COMBO',
        image: '/assets/images/drink-3.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.140/- ',
        // tslint:disable-next-line:max-line-length
        description: 'ORANGE COMBO',
      
    },
    {
        id: '3',
        name: 'STARWBERRY & RASBERRY',
        image: '/assets/images/drink-7.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.130/- ',
        // tslint:disable-next-line:max-line-length
        description: 'STARWBERRY & RASBERRY',
      
    },
    {
        id: '3',
        name: 'WATERMELON & LEMON',
        image: '/assets/images/drink-6.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.120/- ',
        // tslint:disable-next-line:max-line-length
        description: 'WATERMELON & LEMON',
      
    },
];


export const INDIAN: Dish[] = [
    {
        id: '0',
        name: 'INDIAN THALI',
        image: '/assets/images/indian.jpg',
        category: 'lunch',
        featured: true,
        label: 'lunch',
        price: 'Rs.400/- ',
        // tslint:disable-next-line:max-line-length
        description: 'INDIAN THALI',
      
    },
    {
        id: '1',
        name: 'SOUTH COMBO',
        image: '/assets/images/indian.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.350/-',
        description: 'SOUTH COMBO',
        
    },
    {
        id: '2',
        name: 'VEG BRIYANI',
        image: '/assets/images/indian (2).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.380/-',
        description: 'VEG BRIYANI',
        
    },
    {
        id: '3',
        name: 'NON VEG BRIYANI',
        image: '/assets/images/indian (1).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.460/-',
        description: 'NON VEG BRIYANI',
        
    },
    {
        id: '4',
        name: 'SPECIAL DOSA',
        image: '/assets/images/indian (6).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.180/-',
        description: 'SPECIAL DOSA',
        
    },
    {
        id: '5',
        name: 'IDLI',
        image: '/assets/images/indian (4).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.160/-',
        description: 'SOUTH COMBO',
        
    },
    {
        id: '6',
        name: 'PANI PURI',
        image: '/assets/images/indian (3).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.190/-',
        description: 'PANI PURI',     
    },
    {
        id: '7',
        name: 'BASANTHI PUALO',
        image: '/assets/images/lunch7.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.220/-',
        description: 'BASANTHI PUALO',
        
    },
];



export const KIDS: Dish[] = [
    {
        id: '0',
        name: 'GARLIC BREAD',
        image: '/assets/images/breakfast-6.jpg',
        category: 'Kids',
        featured: true,
        label: 'Kids',
        price: 'Rs.200/- ',
        // tslint:disable-next-line:max-line-length
        description: 'CHEESY GARLIC BREAD',
      
    },
    {
        id: '1',
        name: '3 CHEESE RAVIOLI',
        image: '/assets/images/lunch8 (3).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.280/-',
        description: '3 CHEESE RAVIOLI',
        
    },
    {
        id: '2',
        name: 'TOMATO RISOTTO WITH VEGETABLES',
        image: '/assets/images/dinner-3.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.310/-',
        description: 'Italian food with tomato and seasonal vegetables',
        
    },
    {
        id: '3',
        name: 'ROASTED CARROT SOUP',
        image: '/assets/images/soup (2).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.210/-',
        description: 'Puree of roasted carrot flavored with cumin ',
        
    },
    {
        id: '4',
        name: 'CREAMY CORN SOUP ',
        image: '/assets/images/soup (3).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.200/-',
        description: 'Rich creamy corn soup',
        
    },
    {
        id: '5',
        name: 'PAN CAKE',
        image: '/assets/images/dessert-1.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.180/-',
        description: 'PAN CAKE',
        
    },
    {
        id: '6',
        name: 'PIZZA',
        image: '/assets/images/img (2).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.300/-',
        description: 'Pizza',     
    },
    {
        id: '7',
        name: 'SPAHETTI',
        image: '/assets/images/dishes_3.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.250/-',
        description: 'spaghetti',
        
    },
];



export const MAIN: Dish[] = [
    {
        id: '0',
        name: 'GRILL FRIED CHICKEN',
        image: '/assets/images/dishes_4.jpg',
        category: 'lunch',
        featured: true,
        label: 'lunch',
        price: 'Rs.500/- ',
        // tslint:disable-next-line:max-line-length
        description: 'Shallow crumb fried chicken breast serve with mash potato',
      
    },
    {
        id: '1',
        name: 'AGLIO PEPECINO',
        image: '/assets/images/dishes_1.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.350/-',
        description: 'Pasta tossed in olive oil, garlic & chili flakes',
        
    },
    {
        id: '2',
        name: 'CRUMB FRIED SHRIMB',
        image: '/assets/images/dishes_2.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.420/-',
        description: 'Pasta tossed in olive oil, garlic & chili flakes',
        
    },
    {
        id: '3',
        name: 'CHERMOULA MARINATED FISH',
        image: '/assets/images/breakfast-4.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.510/-',
        description: 'A delectable,New York Style marinated fish, spiced with Indian cardamoms',
        
    },
    {
        id: '4',
        name: 'LAMB BOURGNON ',
        image: '/assets/images/12.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.510/-',
        description: 'Seared lamb slowly cooked in brown sauce with shallots, carrot, served with vegetables & herb rice',
        
    },
    {
        id: '5',
        name: 'HARISSA SPICED PIZZA',
        image: '/assets/images/img (2).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.550/-',
        description: 'Pizza topped with tomato sauce, spicy hot grilled chicken',
        
    },
    {
        id: '6',
        name: 'BRAISED LAMB SHANK',
        image: '/assets/images/hero_2.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.300/-',
        description: 'Slow braised lamb shank cooked with wine, herb & serve with rosemary jusChapathi with many varities of traditional Indian curry.',     
    },
    {
        id: '7',
        name: 'PERI CHICKEN PIZZA',
        image: '/assets/images/img (2).jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.600/-',
        description: 'Pizza topped with tomato sauce, spicy hot grille chicken & mozzarella',
        
    },
];



export const SPL: Dish[] = [
    {
        id: '1',
        name: 'HERB SPICED CRAB',
        image: '/assets/images/dinner-1.jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.310/-',
        description: 'Mixture of crab meat seasoned with herb & spice serve with cocktail sauce',
        
    },
    {
        id: '2',
        name: 'BASANTHI PUALO',
        image: '/assets/images/lunch7.jpg',
        category: 'lunch',
        featured: false,
        label: 'Lunch',
        price: 'Rs.220/-',
        description: 'BASANTHI PUALO',
        
    },
    {
        id: '3',
        name: 'AMERICAN CHOCOLATE TART ',
        image: '/assets/images/dessert-3.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.300/-',
        description: 'AMERICAN CHOCOLATE TART',
        
    },
    {
        id: '4',
        name: 'BERRY CHEESE CAKE',
        image: '/assets/images/dessert 2.jpg',
        category: 'dessert',
        featured: false,
        label: 'dessert',
        price: 'Rs.290/-',
        description: 'BERRY CHEESE CAKE',
        
    },
    {
        id: '5',
        name: 'CREAMY MUSHROOM SOUP',
        image: '/assets/images/soup (1).jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.230/-',
        description: 'Puree of mushroom & finished with cream)',
        
    },
    {
        id: '6',
        name: 'TOMATO RISOTTO WITH VEGETABLES',
        image: '/assets/images/dinner-3.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.310/-',
        description: 'Italian food with tomato and seasonal vegetables',
        
    },
    {
        id: '7',
        name: 'ORANGE COMBO',
        image: '/assets/images/drink-3.jpg',
        category: 'drinks',
        featured: true,
        label: 'drinks',
        price: 'Rs.140/- ',
        // tslint:disable-next-line:max-line-length
        description: 'ORANGE COMBO',
      
    },
    {
        id: '8',
        name: 'GARLIC BREAD',
        image: '/assets/images/breakfast-6.jpg',
        category: 'Kids',
        featured: true,
        label: 'Kids',
        price: 'Rs.200/- ',
        // tslint:disable-next-line:max-line-length
        description: 'CHEESY GARLIC BREAD',
      
    },
    {
        id: '9',
        name: '3 CHEESE RAVIOLI',
        image: '/assets/images/lunch8 (3).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.280/-',
        description: '3 CHEESE RAVIOLI',
        
    },
    {
        id: '10',
        name: 'TOMATO RISOTTO WITH VEGETABLES',
        image: '/assets/images/dinner-3.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.310/-',
        description: 'Italian food with tomato and seasonal vegetables',
        
    },
    {
        id: '11',
        name: 'ROASTED CARROT SOUP',
        image: '/assets/images/soup (2).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.210/-',
        description: 'Puree of roasted carrot flavored with cumin ',
        
    },
    {
        id: '12',
        name: 'CREAMY CORN SOUP ',
        image: '/assets/images/soup (3).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.200/-',
        description: 'Rich creamy corn soup',
        
    },
    {
        id: '13',
        name: 'PAN CAKE',
        image: '/assets/images/dessert-1.jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.180/-',
        description: 'PAN CAKE',
        
    },
    {
        id: '14',
        name: 'PIZZA',
        image: '/assets/images/img (2).jpg',
        category: 'Kids',
        featured: false,
        label: 'Kids',
        price: 'Rs.300/-',
        description: 'Pizza',     
    },
    
   
];



export const STARTERS: Dish[] = [
    {
        id: '0',
        name: 'CREAMY MUSHROOM SOUP',
        image: '/assets/images/soup (1).jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.230/-',
        description: 'Puree of mushroom & finished with cream)',
        
    },
    {
        id: '1',
        name: 'BASIL INFUSED TOMATO SOUP',
        image: '/assets/images/soup (5).jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.200/-',
        description: 'Roasted tomato soup flavored with basil',
        
    },
    {
        id: '2',
        name: 'CHICKEN CAESAR SALAD',
        image: '/assets/images/breakfast-2.jpg',
        category: 'Starters',
        featured: true,
        label: 'Starters',
        price: 'Rs.260/- ',
        // tslint:disable-next-line:max-line-length
        description: 'Crispy iceberg lettuce, with grilled chicken, garlic croutons parmesan shavings & mix with Caesar dressing',
      
    },
    {
        id: '3',
        name: 'CRISPY CHICKEN ',
        image: '/assets/images/breakfast-5.jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.350/-',
        description: 'fried chicken season with herbs & fried, serve with chili mayo',
        
    },
    {
        id: '4',
        name: 'FISH CALZONE',
        image: '/assets/images/lunch-1.jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.235/-',
        description: 'Cooked fish strips tossed in roasted garlic sauce',
        
    },
    {
        id: '5',
        name: 'HERB SPICED CRAB',
        image: '/assets/images/dinner-1.jpg',
        category: 'Starters',
        featured: false,
        label: 'Starters',
        price: 'Rs.310/-',
        description: 'Mixture of crab meat seasoned with herb & spice serve with cocktail sauce',
        
    },
    
    
];
