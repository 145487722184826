import { Component, OnInit } from '@angular/core';
import {Dish} from '../shared/dish';
import {DishService} from '../services/dish.service';


   @Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
  })
  
  export class MenuComponent implements OnInit {
  
    dishes:Dish[];
    desserts:Dish[];
    mains:Dish[];
    indians:Dish[];
    kids:Dish[];
    drinks:Dish[];
    starters:Dish[];
    spls:Dish[];

    selectedDish : Dish;
    regularDistribution = 100 / 3;
  
    
  constructor(private dishService:DishService) { 
 
   
}
  ngOnInit() {
    this.dishes=this.dishService.getDishes();
    this.desserts=this.dishService.getDessert();
    this.starters=this.dishService.getStarters();
    this.indians=this.dishService.getIndian();
    this.mains=this.dishService.getMain();
    this.kids=this.dishService.getKids();
    this.drinks=this.dishService.getDrinks();
    this.spls=this.dishService.getSpl();
  }
  
  onSelect(dish:Dish)
  {
  this.selectedDish =dish;
  }
  breakpoint: number;
  responsive = true;
  cols = 1;

}
