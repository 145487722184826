import { Injectable } from '@angular/core';
import {Dish} from '../shared/dish';
import {DISHES} from '../shared/dishes';
import {DESSERT} from '../shared/dishes';
import {DRINKS}from '../shared/dishes';
import {INDIAN}from '../shared/dishes';
import {KIDS} from '../shared/dishes';
import {MAIN}from '../shared/dishes';
import {SPL}from '../shared/dishes';
import {STARTERS}from '../shared/dishes';

@Injectable({
  providedIn: 'root'
})
export class DishService {

  constructor() { }
  
  getDishes(): Dish[] {
    return DISHES;
  }

  getDessert(): Dish[] {
    return DESSERT;
  }
  getDrinks(): Dish[] {
    return DRINKS;
  }
 
  getIndian(): Dish[] {
    return INDIAN;
  }
 
  getKids(): Dish[] {
    return KIDS;
  }
 
  getMain(): Dish[] {
    return MAIN;
  }
 
  getSpl(): Dish[] {
    return SPL;
  }
 
  getStarters(): Dish[] {
    return STARTERS;
  }
 

}
